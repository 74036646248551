:root {
    --color-white-snow: #fafafc;
    --color-blue-accent: #1976d2;
    --color-blue-alice-darker: #718096;
    --color-gray-stormcloud: #4a5568;
}
body {
    background: var(--color-white-snow);
}
#root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: 100vh;
}

* {
    font-family: Segoe UI, sans-serif;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
h1 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
}
h2 {
    font-size: 26px;
    line-height: 1.3;
    font-weight: 500;
}
h3 {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 500;
}
h4 {
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
}
h5 {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
}
a {
    color: var(--color-blue-accent);
    text-decoration: none;
}
